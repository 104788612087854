import React, { forwardRef, useImperativeHandle } from 'react';
import { Row } from 'react-bootstrap';
import {useSpring , animated} from 'react-spring'
import './CarAnimation.css';
import black_car from './assets/black_car.svg';

const Animation = forwardRef((props , ref) => {
    
    const [timerWidth, setTimerWidth] = React.useState(0);
    const [carWidth , setCarWidth] = React.useState(0);
    const [hours , setHours] = React.useState(0);
    const [minutes , setMinutes] = React.useState(0);
    const [seconds , setSeconds] = React.useState(0);
    const animationRowRef = React.useRef(null);
    const carref = React.useRef(null);
    const [animationStarted , setAnimationStarted] = React.useState(false)
    
    const handlresize = () => {
        setTimerWidth(animationRowRef.current.offsetWidth);
        setCarWidth(carref.current.clientWidth);
    }

    const carLoad = () => {
        setCarWidth(carref.current.clientWidth);
    }

    React.useEffect(() => {
        if(props.isRunning){
            if (!props.onBreak){
                console.debug("need to updating animation soon")
                const updateAnimation = setTimeout(()=>{
                    console.debug("updated animation ")
                    api.start({
                        left: (timerWidth - carWidth) - 20,
                        config: { duration: props.totalTime*1000}
                    })
                } , 1000)
                return () => clearTimeout(updateAnimation)
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    } , [timerWidth , carWidth])

    React.useEffect(() => {
        setTimerWidth(animationRowRef.current.offsetWidth);
        setCarWidth(carref.current.clientWidth);
    }, [  ]);

    
    const [animationProps, api] = useSpring(
        () => ({left: 0}));
    

    React.useEffect(()=> {
        if(props.isRunning === true ){ //play button is pressed 
            if(animationStarted === false){ //play button is pressed for the first time
                setAnimationStarted(true)
                api.start({
                    left: (timerWidth - carWidth) - 20 ,
                    config: { duration: props.totalTime*1000}
                })
            }else{ //play button is pressed after pausing animation
                console.debug("animation resumed")
                api.resume()
                //calling start again after resume incase user resized window in between 
                api.start({
                    left: (timerWidth - carWidth) - 20 ,
                    config: { duration: props.totalTime*1000}
                })
            }
        }else{ //pause button is pressed or timer has ended
            if(animationStarted === true && props.timeRemaining > 0){ //pause button is pressed while animating
                console.debug("animation paused")
                api.pause()
            }else{ //this means the user did not pause but that the timer ended without being in pomodoro mode
                handleReset()
            }
        }//eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isRunning])

    React.useEffect(()=>{
        if(props.onBreak===true){
            console.debug("break animation started")
            api.start({
                left: 0,
                config: { duration: props.totalTime*1000}
            })
        }else{
            if(animationStarted === true){
                console.debug("animation started after break")
                api.start({
                    left: (timerWidth - carWidth) - 20,
                    config: { duration: props.totalTime*1000}
                })
            }else{
                console.debug("animation started is false and anitmation hasn't been started again")
            }
        }//eslint-disable-next-line react-hooks/exhaustive-deps
    } , [props.onBreak])


    React.useEffect(() => {
        // convert timeremaining to minutes and seconds
        if(props.timeRemaining > 0){
            const _hours = Math.floor(props.timeRemaining / 3600);
            var time_remaining = props.timeRemaining % 3600;
            const _minutes = Math.floor(time_remaining / 60);
            time_remaining = time_remaining % 60;
            const _seconds = time_remaining;
            setHours(_hours);
            setMinutes(_minutes);
            setSeconds(_seconds);
        }
    }
    , [props.timeRemaining]);


    function handleReset(){
        console.debug("user pressed reset")
        setHours(0)
        setMinutes(0)
        setSeconds(0)
        setAnimationStarted(false)
        api.set({
            left: 0
        })

    }

    useImperativeHandle(ref  , () => {
        return {
            handleReset,
            handlresize

        }
    })


    return (
        <div ref={animationRowRef}  className="timerAnimation">
            <Row>
                <p className="clockStartCount">00:00:00</p>
                <p className="clockEndCount">{("0"+hours).slice(-2)}:{("0"+minutes).slice(-2)}:{("0"+seconds).slice(-2)}</p>
            </Row>
            <animated.img onLoad={carLoad}  ref={carref} src={black_car} className="black_car" alt="black_car" style={{left: animationProps.left}} />
        </div>
   
    
    );
})

export default Animation;